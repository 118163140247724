.modal {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(251, 255, 44, 0.678);
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  max-width: 360px;
  text-align: center;
}

.modal-title {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

.modal-footer {
  align-items: center;
  margin-top: 10px;
  color: #333;
}

.modal-divider {
  margin: 10px 0;
  border-bottom: 1px solid #ddd;
}

.modal-buttons {
  display: flex;
  justify-content: center;
}
.modal-text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #ddd;
}

.button-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
