body {
  margin: 0;
  padding: 0;
  background-image: url("image/great-wall-of-china-at-sunset.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  position: relative;
  height: 100vh;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

.App-header {
  margin-top: 20px;
}

.header-image {
  width: 100%;
  max-width: 800px;
}

.side-image-container {
  position: absolute;
  bottom: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-side {
  left: 5%;
  transform-origin: top right;
  animation: swing-left 8s infinite ease-in-out;
}

.right-side {
  right: 5%;
  transform-origin: top left;
  animation: swing-right 8s infinite ease-in-out;
  animation: swing-right 8s infinite ease-in-out;
  animation-delay: 1s;
}

.thread {
  width: 2px;
  height: 50vh;
  background-color: black;
  margin-bottom: -2px;
}

.side-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: contain;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: -5px;
}

@keyframes swing-right {
  0% {
    transform: rotate(7deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(7deg);
  }
}

@keyframes swing-left {
  0% {
    transform: rotate(-7deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-7deg);
  }
}
